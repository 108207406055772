import { Col, Container, Row } from "react-bootstrap";

export const Banner = ({ language }) => {
  const bannerText = {
    tagLine: language === "CZ" ? "Roman Vaněk" : "Roman Vaněk",
    title:
      language === "CZ"
        ? "Vývoj firmwaru a hardwaru"
        : "Firmware and Hardware Development",
    description:
      language === "CZ"
        ? "Zabívám se vývojem firmwaru a hardwaru pro vestavěné systémy. Nabízím kompletní návrh jednoúčelových elektronických zařízení"
        : "I specialize in firmware and hardware development for embedded systems. I offer complete design of dedicated electronic devices",
    button: language === "CZ" ? "Nezávazná poptávka" : "Request a Quote",
  };

  const handleEmailClick = () => {
    const subject = language === "CZ" ? "Poptávka" : "Request for Quote";
    const body =
      language === "CZ"
        ? "Dobrý den, rád bych se dozvěděl více o vašich službách."
        : "Hello, I would like to learn more about your services.";
    window.location.href = `mailto:roman.vanek@rvembedded.cz?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} ms={6} xl={6}>
            <h1>{bannerText.title}</h1>
            <p>{bannerText.description}</p>
            <span className="navbar-text">
              <button onClick={handleEmailClick}>{bannerText.button}</button>
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
