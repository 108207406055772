import "./App.css";
import { useState } from "react";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { About } from "./components/About";
import { Projects } from "./components/Projects";
import { Footer } from "./components/Footer";
import LaunchIt from "./components/Apps/LaunchIt";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [language, setLanguage] = useState("CZ");

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "CZ" ? "EN" : "CZ"));
  };

  return (
    <Router>
      <AppContent language={language} toggleLanguage={toggleLanguage} />
    </Router>
  );
}

function AppContent({ language, toggleLanguage }) {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <NavBar language={language} toggleLanguage={toggleLanguage} />
              <Banner language={language} />
              <About language={language} />
              <Projects language={language} />
              <Footer language={language} />
            </>
          }
        />

        <Route
          path="/launchit"
          element={
            <>
              <LaunchIt />
              <Footer language={language} />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
