import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import bmsImg from "../assets/img/bmsImg.jpg";
import fucImg from "../assets/img/fucImg.png";
import probeImg from "../assets/img/probeImg.png";
import aviImg from "../assets/img/aviImg.png";
import relImg from "../assets/img/relImg.png";
import { ProjectCard } from "./ProjectCard";

export const Projects = ({ language }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const projects = [
    {
      title:
        language === "CZ"
          ? "Battery Management System"
          : "Battery Management System",
      description:
        language === "CZ"
          ? "Měření a správa článků v batery packu"
          : "Measurement and management of cells in the battery pack",
      imgUrl: bmsImg,
    },
    {
      title:
        language === "CZ" ? "Filter Unit Controller" : "Filter Unit Controller",
      description:
        language === "CZ"
          ? "Jednotka pro přepínání mezi vf filtry"
          : "Unit for switching between RF filters",
      imgUrl: fucImg,
    },
    {
      title:
        language === "CZ"
          ? "Active electrode for electrical impedance tomography"
          : "Active electrode for electrical impedance tomography",
      description:
        language === "CZ"
          ? 'Realizace aktivní elektrody v rámci mé bakalářské práce "Modular system for electrical impedance tomography"'
          : 'Realization of the active electrode as a part of my bachelor thesis "Modular system for electrical impedance tomography"',
      imgUrl: probeImg,
    },
    {
      title:
        language === "CZ"
          ? "Analog voltage measurement card"
          : "Analog voltage measurement card",
      description:
        language === "CZ"
          ? "Karta analogového měření jako součást většího systému"
          : "Analog measurement card as part of a larger system",
      imgUrl: aviImg,
    },
    {
      title:
        language === "CZ"
          ? "Universal swithing card"
          : "Universal swithing card",
      description:
        language === "CZ"
          ? "Karta pro spínání volitelných signálů a napájení jako součást většího systému"
          : "Optional signal and power switching card as part of a larger system",
      imgUrl: relImg,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col>
            <div className="project-bx">
              <h2>{language === "CZ" ? "Projekty" : "Projects"}</h2>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="project-slider"
              >
                {projects.map((project, index) => {
                  return <ProjectCard key={index} {...project} />;
                })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
